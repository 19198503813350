@import "scss/index";
@include media-mobile {

  .carousel {

    :global(.glider-track) {
      padding: 0 8px;
    }

    :global(.glider-slide) {
      padding: 0 8px;
    }
  }
}
