@import "scss/index";
:global {
  .leaflet-vertex-icon, .leaflet-middle-icon {
    border: 2px solid #3D75E4 !important;
    border-radius: 8px !important;
    width: 12px !important;
    height: 12px !important;
    &:hover {
      background-color: #3D75E4 !important;
    }
  }
}