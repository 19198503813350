@mixin media-mobile {
  :global(.mobile) {
    @content;
  }
}

@mixin media-desktop {
  :global(.desktop) {
    @content;
  }
}

@mixin media-tablet {
  :global(.tablet) {
    @content;
  }
}

@mixin media-except-desktop {
  :global(.tablet),
  :global(.mobile) {
    @content;
  }
}

@mixin media-except-mobile {
  :global(.tablet),
  :global(.desktop) {
    @content;
  }
}

@mixin media-retina {
  $retina-variable: 'only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)';
  @media #{$retina-variable} { @content; }
}

@mixin media-retina-3x {
  $retina-variable: 'only screen and (-webkit-min-device-pixel-ratio: 2.5), only screen and (min-resolution: 288dpi)';
  @media #{$retina-variable} { @content; }
}

