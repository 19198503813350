@import "scss/index";
@mixin button-style-color($color, $opacity) {
  background-color: $color;

  &:not(.disabled) {
    @include button-colors($color, background-color);
  }
}

.container {
  background-color: rgba($color-rocky, 0.08);
}



@include media-mobile {

  .button {
    height: 40px;
    @include button-style-color($color-rocky, 0.4);
  }

  .input {

    & > div {
      height: 40px;
      padding-left: 16px;
      padding-right: 12px;
      border-radius: 0;
      background-color: transparent !important;

      & > a {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    input {
      margin-top: 0;
    }
  }

}

@include media-except-mobile {

  .button {
    height: 64px;
    @include button-style-color($color-rocky, 0.4);
  }

  .input {

    & > div {
      height: 64px;
      padding-left: 32px;
      padding-right: 12px;
      border-radius: 0;
      background-color: transparent !important;

      & > a {
        margin-top: 22px;
        margin-bottom: 22px;
      }
    }

    input {
      margin-top: 0;
    }
  }
}
