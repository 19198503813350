@import "scss/index";
@include media-mobile {

  .header {
    height: 48px;
  }

  .logo {
    width: 82px;
  }
}
