@import "scss/index";
@mixin glider-offset($offset) {
  :global(.glider-track) {
    margin: 0 -#{$offset}px;
  }

  :global(.glider-slide) {
    margin: 0 #{$offset}px;
  }
}

body .carousel {
  @include glider-offset(4);

  :global(.glider-prev),
  :global(.glider-next) {
    top: 0;
    height: 72px;
    transform: none;
  }
}

@include media-mobile {

  body .carousel {

    :global(.glider-track) {
      padding-left: 24px;
    }
  }
}

@include media-except-mobile {

  body .carousel {

    :global(.glider-prev) {
      left: -24px;
    }

    :global(.glider-next) {
      right: -24px;
    }
  }
}
