@import "scss/index";
.iconWrapper {

  .icon {
    opacity: 0.72;
    @include basic-transition();

    &:hover {
      opacity: 1;
    }
  }
}
