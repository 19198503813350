@import "scss/index";
.shadow {
  @include medium-shadow-titanic
}

.zooms {
  top: 16px;
  right: 16px;
  left: 16px;
}

.controls {
  position: absolute;
  top: 0;
  right: 0;
}

:global {
  .leaflet-vertex-icon,
  .leaflet-middle-icon {
    width: 12px !important;
    height: 12px !important;
    border: 2px solid #3d75e4 !important;
    border-radius: 8px !important;

    &:hover {
      background-color: #3d75e4 !important;
    }
  }

  .leaflet-editable-drawing{
    cursor: pointer;
  }

  .leaflet-container {
    &.editing {
      cursor: pointer !important;
    }
  }
}