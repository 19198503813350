@import "scss/index";
.modal {
  padding: 0 !important;

  :global(.modal) {
    height: 100%;

    & > div {
      height: 100%;
    }
  }
}

.selected {
  position: relative;
  background: $color-arrival;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 4px;
    height: 32px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: $color-rocky;
  }
}