@import "scss/index";
.popup {
  margin: 0;

  :global(.leaflet-popup-content-wrapper) {
    border-radius: 16px 16px 16px 0;
    @include big-shadow-titanic-1;
    padding: 0;
  }

  :global(.glider) {
    border-radius: 16px 16px 16px 0;
  }

  :global(.leaflet-popup-content) {
    width: 242px;
    margin: 0;
  }

  :global(.leaflet-popup-tip-container),
  :global(.leaflet-popup-close-button) {
    display: none;
  }

  :global(.glider-dots) {
    padding-bottom: 8px;
  }

  :global(.glider-prev),
  :global(.glider-next) {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    background: #fff;
    border-radius: 100%;
    @include small-shadow-titanic;
  }

  :global(.glider-prev) {
    margin-left: -16px;
  }

  :global(.glider-next) {
    margin-right: -16px;
  }

}
