@import "scss/index";
.modal {

  :global(.modal) {
    max-width: 774px !important;
  }
}

.carouselRoot {
  & > div:first-child {
    max-width: 774px;
    width: 100vw;
    height: calc(100vh - 84px);
  }
}
