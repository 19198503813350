@import "scss/index";
.grid {
  grid-gap: 16px;
  grid-template-columns: 1fr 88px 88px 96px;
}

.border {
  border-top: 1px solid $color-arrival;
}

.channel {
  min-width: 84px;
}

.expandable {

  &:hover {
    background-color: $color-rocky-08;
  }
}
