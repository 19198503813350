@import "scss/index";
.itemLink {

  &:not(:global(.opacity-24)) {

    &:hover > div {
      color: $color-godfather;
    }
  }
}

.containerWrapper{
  @include uikit-scrollbar;
}
